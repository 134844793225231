import { template as template_a988b6ea59464c1eb2d80050b016df94 } from "@ember/template-compiler";
const FKControlMenuContainer = template_a988b6ea59464c1eb2d80050b016df94(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
